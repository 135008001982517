<template>
  <div class="login-container">
    <div class="login-bg">
      <div class="login-header">
        <h2>
          <span @click="clickActiveLogin(1)" :class="{ 'selectActive': activeLogin }">{{$t('login.loginTxt10')}}</span>
          <span v-if="use_phone_login" @click="clickActiveLogin(2)" :class="{ 'selectActive': !activeLogin }"
            style="margin-left: 12px;">{{$t('login.loginTxt11')}}</span>
        </h2>
      </div>
      <div class="login-logo">
        <img src="@assets/img/loginBanner.png" alt />
      </div>
      <div class="form-group" v-if="activeLogin">
        <div class="form-item" style="margin-top:0;">
          <input id="phone" type="text" :placeholder="$t('login.loginTxt2')" v-model="username" />
        </div>
        <div class="form-item">
          <input id="code" type="password" :placeholder="$t('login.loginTxt3')" v-model="pwd" />
        </div>
      </div>
      <div class="form-group" v-if="!activeLogin">
        <div class="form-item" style="margin-top:0;">
          <input autocomplete="off" name="shouJiHaoName" id="phone2" type="text" placeholder="请输入手机号码" v-model="shouJiHao" />
        </div>
        <div class="form-item">
          <input autocomplete="off" name="shouJiCodeName" id="code2" type="text" placeholder="验证码" v-model="shouJiCode" />
          <div class="send-btn"><a @click.stop="getShouJiCode" href="javascript:void(0);" class="send-btn-link">{{
              showtime }}</a></div>
        </div>
      </div>
      <div v-if="need_slider_code && activeLogin">
        <div class="text_verification" v-if="vertify_mode == 1">
          <input v-model="enterCode" class="up1" type="text" :placeholder="'验证码'" />
          <div class="show_identify">
            <Identify :identifyCode="identifyCode"></Identify>
          </div>
          <div class="changeCode" @click="refreshCode">看不清楚，换一张</div>
        </div>
        <div class="dragVerify" v-else>
          <drag-verify ref="dragVerify" class="drag" :width="drag_width" :height="drag_height"
            :isPassing.sync="isPassing2" text="请按住滑块拖动" successText="验证通过" handlerIcon="fa fa-angle-double-right"
            successIcon="fa fa-check" @passcallback="pass">
            <i v-show="!isPassing2" slot="textBefore" class="fa fa-lock"></i>
          </drag-verify>
        </div>
      </div>
      <div class="form-password" v-if="activeLogin">
        <div class="rem-password">
          <input type="checkbox" id="checkbox" v-model="checked" />
          <label for="checkbox">{{ $t('login.loginTxt4') }}</label>
        </div>
        <div v-if="forgetPsd" class="forget-password" @click="handleForget">
          {{ $t('login.loginTxt5') }}
        </div>
      </div>
      <div class="button-group">
        <a class="login-btn" @click="handleLogin" :class="isJzDj?'jinZhiYongHuDianJi':''">{{
          $t('login.loginTxt6')
        }}</a>
      </div>
      <div class="button-group">
        <van-dropdown-menu direction="up">
          <van-dropdown-item v-model="language" :options="langs" @change="handleCommand" />
        </van-dropdown-menu>
      </div>
      <div class="nav-footer" :style="{
        color: '#000'
      }">
        当前版本
        {{ $version }}
        <a v-if="ba_url && ba_caption" :href="ba_url" target="new_frame"
          style="text-decoration: none; color: #828282; ">{{ ba_caption }}
        </a>
      </div>
    </div>
    <ddBind ref="ddBind" @beforeClose="goto" />

    <!-- 启用双因素登录认证 -->
    <van-popup v-model="showSMSverification" round :style="{ height: '60%', width: '80%' }" class="SMSverification_pop">
      <van-nav-bar title="短信验证" :fixed="true">
        <template #right>
          <van-icon name="cross" size="18" color="#ffffff" @click="showSMSverification = false" />
        </template>
      </van-nav-bar>
      <div class="content">
        <!-- <van-form>
          <van-field v-model="SMSverificationCode"
                     type="digit"
                     placeholder="请输入验证码"
                     label="验证码" />
          <div class="textsss">请输入手机收到的6位短信验证码</div>
        </van-form> -->
        <van-password-input :value="SMSverificationCode" :gutter="10" :length="6" :mask="false" :focused="showKeyboard"
          info="请输入手机收到的6位短信验证码" @focus="showKeyboard = true" />
        <van-number-keyboard v-model="SMSverificationCode" :maxlength="6" :show="showKeyboard"
          @blur="showKeyboard = false" />
        <!-- <div class="footerBox">
          <div class="flexBox">
            <van-button @click="SMSverificationClose"
                        type="info"
                        :disabled="!SMSverificationCode"
                        style="width: 80%;height: 100%;">{{$t('login.loginTxt6')}}</van-button>

          </div>
        </div> -->
      </div>
    </van-popup>
  </div>
</template>
<script>
import { Dialog, Notify } from 'vant'
import { isChangePwd, checkUserLogin, getSmsCode,checkUserLoginBySmsCode } from '@api/login.js'
import ddBind from '@components/ddBind'
import { Toast, DropdownMenu, DropdownItem } from 'vant'
import { getOpenId } from '@/utils/auth'
import { getLangList, getPwdPolicy,getWaterMarkTxt } from '@api/wx'
import { decrypt } from '@/utils/aes.js'
import Identify from '@components/imageVerification/identify'
import dragVerify from '@components/dragVerify'
import { removeWatermark, setWaterMark } from '@/utils/watermark'

// import Cookies from 'js-cookie'
export default {
  components: {
    ddBind,
    Identify,
    dragVerify
  },
  name: 'login',
  data () {
    let forgetPsd =
      window.hide_forget_password == 1 || window.hide_forget_password == '1'
        ? false
        : true
    let v8_login_checked = localStorage.getItem('v8_login_checked')
    let v8_login_username = localStorage.getItem('v8_login_username')
    let v8_login_pwd = localStorage.getItem('v8_login_pwd')
    let checkedStatus = false
    let checkedUsername = ''
    let checkedPass = ''
    if (v8_login_checked == 1)
    {
      checkedStatus = true
    } else
    {
      checkedStatus = false
    }
    if (v8_login_username)
    {
      checkedUsername = v8_login_username
    } else
    {
      checkedUsername = ''
    }
    if (v8_login_pwd && checkedStatus)
    {
      checkedPass = v8_login_pwd
    } else
    {
      checkedPass = ''
    }
    return {
      ba_url: window.ba_url || '', // 工信部网址
      ba_caption: window.ba_caption || '', // 备案号
      forgetPsd,
      username: checkedUsername,
      pwd: checkedPass,
      checked: checkedStatus,
      language: 0,
      langs: [],
      lang: [],
      langid: 0,
      need_slider_code: false, // 每次登录需要验证码
      vertify_mode: 0, // 验证方式 0滑块验证，1文字验证
      identifyCode: '',
      identifyCodes: '123456789ABCDEFGHGKMNPQRSTUVWXYZabcdefghgkmnpqrstuvwxyz', // 验证码规则
      enterCode: '', // 输入的验证码
      // --------
      drag_width: 300,
      drag_height: 45,
      isPassing2: false,
      use_double_factor_login_auth: false, // 是否启用双因素登录认证（短信验证码模式）
      showSMSverification: false, // 短信验证弹窗
      SMSverificationCode: '',
      returnSMScode: '',
      showKeyboard: false,
      activeLogin:true,
      shouJiHao:"",
      shouJiCode:"",
      timeCounter:null,
      showtime:"获取验证码",
      isJzDj:false,
      use_phone_login:true
    }
  },
  watch: {
    SMSverificationCode (n) {
      if (n.length == 6)
      {
        if (n == this.returnSMScode)
        {
          this.showSMSverification = false
          this.SMSverificationCode = ''
          Toast(this.$t('login.loginTxt9'))
          if (window.DingDing)
          {
            // 启用钉钉，开始鉴权
            // this.DingDingSign()
            this.$refs.ddBind.checkDdUserid()
            // this.$refs.ddBind.DingDingSign()
          } else
          {
            this.goto()
          }
        } else
        {
          Toast('请输入正确的验证码！')
        }
      }
    }
  },
  created () {
    this.drag_width = window.screen.width - 30
    // window.login = this
    this.getLangue()
    // 生成验证码
    this.refreshCode()
    if (localStorage.getItem('langid'))
    {
      if (localStorage.getItem('langid') != 'undefined')
      {
        localStorage.setItem('langid', localStorage.getItem('langid'))
      } else
      {
        localStorage.setItem('langid', '0')
      }
    } else
    {
      localStorage.setItem('langid', this.langid || '0')
      // Cookies.set('langid', this.langid)
    }
  },
  mounted () {
    if (
      localStorage.getItem('v8_login_login') === '1' &&
      sessionStorage.out != '1'
    )
    {
      this.handleLogin()
    }
  },
  methods: {
    getShouJiCode() {
      if(this.timeCounter){
        return;
      }
      if(!this.shouJiHao){
        Notify({
          type: 'danger',
          message: "请输入手机号码！"
        })
        return false;
      }
      getSmsCode({
        mobilephone: this.shouJiHao,
        notest:1
      }).then((res) => {
        if (res.iserror == 0){
          let wxToken = sessionStorage.getItem('token')
          let code = decrypt(wxToken, res.topvalue);
          localStorage.setItem("MloginCode", code); // 存下验证码
          this.countDown(60)
          if (code === "1234") {
            this.shouJiCode = code;
          }
        } else if (res.iserror == 1)
        {
          Notify({
            type: 'danger',
            message: res.errormsg
          })
        }
      });
    },
    clickActiveLogin (type) {
      if(type == 1){
        this.activeLogin = true
      }else{
        this.shouJiHao = '' 
        this.shouJiCode = ''
        this.activeLogin = false
      }
      clearTimeout(this.timeCounter)
      this.timeCounter = null;
      this.showtime = "获取验证码";
    },
    countDownText(s) {
      this.showtime = `${this.formatNum(s)}s后重发`;
    },
    formatNum(n) {
      return n < 10 ? '0' + n : n;
    },
    countDown(times) {
      this.showtime = `${times}s后重发`;
      if (!this.shouJiHao){
        return;
      }
      const interval = 1000;
      let count = 0;
      const _this = this;
      this.timeCounter = setTimeout(countDownStart, interval);
      function countDownStart () {
        if (_this.timeCounter == null){
          return false;
        }
        count++;
        _this.countDownText(times - count);
        if (count > times){
          clearTimeout(_this.timeCounter);
          _this.timeCounter = null;
          _this.showtime = "获取验证码";
        } else {
          _this.timeCounter = setTimeout(countDownStart, interval);
        }
      }
    },
    pass () { },
    // 切换验证码
    refreshCode () {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
      // console.log(this.identifyCode)
    },
    // 生成随机验证码
    makeCode (o, l) {
      for (let i = 0; i < l; i++)
      {
        this.identifyCode += this.identifyCodes[
          Math.floor(Math.random() * (this.identifyCodes.length - 0) + 0)
        ]
      }
    },
    async checkPwd () {
      let todo = true
      const res = await isChangePwd({ username: this.username })
      // console.log(res)
      if (res.topvalue)
      {
        switch (res.topvalue)
        {
          case '0':
            break
          case '1':
            todo = false
            Dialog.confirm({
              message: window.outdate_login_changepwd_info || res.errormsg
            })
              .then(() => {
                this.$router.push({
                  path: '/changePassword',
                  query: {
                    username: res.totalcount||this.username,
                    form: 'login',
                    time: new Date().getTime()
                  }
                })
              })
              .catch(() => {
                localStorage.setItem('v8_login_login', '0')
                localStorage.removeItem('userid')
                localStorage.removeItem('userInfo')
                sessionStorage.removeItem('OpenId')
                this.$nextTick(() => {
                  this.$router.push('/login')
                })
              })

            break
          case '2':
            todo = false
            Dialog.alert({
              message: res.errormsg
            }).then(() => {
              this.$router.push({
                path: '/changePassword',
                query: {
                  username: res.totalcount||this.username,
                  form: 'login',
                  time: new Date().getTime()
                }
              })
            })
            break
          case '3':
            todo = false
            Dialog.alert({
              message: window.first_login_changepwd_info || res.errormsg
            }).then(() => {
              this.$router.push({
                path: '/changePassword',
                query: {
                  username: res.totalcount||this.username,
                  form: 'login',
                  time: new Date().getTime()
                }
              })
            })

            break
          default:
            break
        }
      }
      return todo
    },
    async handleLogin () {
      
      if(this.isJzDj){
        console.log("已经禁止点击！！")
        return;
      }
      if(this.activeLogin){
        if (!this.username){
          Toast(this.$t('login.loginTxt7'))
          return false
        }
        if (!this.pwd){
          Toast(this.$t('login.loginTxt8'))
          return false
        }
        // 登录验证
        if (this.need_slider_code){
          if (this.vertify_mode == 0){
            if (!this.isPassing2){
              Toast('请拖动滑块完成验证！')
              return false
            }
          } else{
            // 文字验证 判断验证码是否正确
            if (this.enterCode.toLowerCase() != this.identifyCode.toLowerCase()) {
              Toast('请输入正确的验证码！')
              this.refreshCode()
              return false
            }
          }
        }
        this.isJzDj = true;
        let todo = await this.checkPwd()
        if (!todo){
          this.isJzDj = false;
          return
        }
        let hasOpenId = getOpenId()
        if (hasOpenId === '0') hasOpenId = ''
        checkUserLogin({
          username: this.username.trim(),
          pwd: this.pwd,
          langid: this.langid || 0,
          openid: hasOpenId || ''
        }).then(res => {
          this.isJzDj = false;
          if (res.iserror == '0'){
            if (res.data[0].userid == -1){
              Toast(res.data[0].errormsg)
            } else{
              localStorage.setItem('xuYaoMiMa', 1)
              localStorage.setItem('v8_login_pwd', this.pwd)
              if (this.checked)
              {
                localStorage.setItem('v8_login_checked', 1)
                localStorage.setItem('v8_login_username', this.username.trim())
                // localStorage.setItem('v8_login_pwd', this.pwd)
              } else
              {
                localStorage.setItem('v8_login_checked', 0)
                localStorage.setItem('v8_login_username', '')
                // localStorage.setItem('v8_login_pwd', '')
              }
              localStorage.setItem('userid', res.data[0].userid)
              localStorage.setItem('userInfo', JSON.stringify(res.data[0]))
              getWaterMarkTxt({
                username:this.username.trim()
              }).then(res=>{
                const ressy = res.data;
                if(ressy && ressy.length > 0){
                  const syobj = ressy[0];
                  if(syobj.show_watermark == 1){
                    localStorage.setItem("sysShuiYin",JSON.stringify(syobj))
                    setWaterMark(
                      syobj.watermark_line1,
                      syobj.watermark_line2,
                      syobj.watermark_line3
                    )
                  }else{
                    localStorage.removeItem("sysShuiYin")
                  }
                }
              })
              sessionStorage.setItem('out', '2')
              // 判断是否启用双因素登录认证（短信验证码模式）-- 启用打开弹窗否则跳转
              if (this.use_double_factor_login_auth){
                // 打开弹窗
                this.showSMSverification = true
                // 发送短信验证码
                getSmsCode({
                  mobilephone: res.data[0].mobilephone,
                }).then((resu) => {
                  if (resu.iserror == 0)
                  {
                    let wxToken = sessionStorage.getItem('token')
                    let code = decrypt(wxToken, resu.topvalue);
                    this.returnSMScode = code
                    if (code == "1234") this.SMSverificationCode = code
                    this.showKeyboard = true
                  }
                })
              } else{
                Toast(this.$t('login.loginTxt9'))
                if (window.DingDing)
                {
                  // 启用钉钉，开始鉴权
                  // this.DingDingSign()
                  this.$refs.ddBind.checkDdUserid()
                  // this.$refs.ddBind.DingDingSign()
                } else
                {
                  this.goto()
                }
              }
            }
          } else {
            Notify({
              type: 'danger',
              message: res.errormsg
            })
            if (this.vertify_mode == 1)
            {
              this.refreshCode()
            } else
            {
              this.$refs.dragVerify.reset();
              this.isPassing2 = false;
            }
          }
        })
      }else{
        if(!this.shouJiHao){
          Notify({
            type: 'danger',
            message: "请输入手机号码！"
          })
          return false;
        }
        if(!this.shouJiCode){
          Notify({
            type: 'danger',
            message: "验证码不能为空！"
          })
          return false;
        }
        if(localStorage.getItem("MloginCode") == this.shouJiCode){
          let hasOpenId = getOpenId()
          if (hasOpenId === '0'){
            hasOpenId = ''
          }
          checkUserLoginBySmsCode({
            username:this.shouJiHao,
            isapp:1,
            openid: hasOpenId || '',
            langid: this.langid || 0,
          }).then(res=>{
            if (res.iserror == '0'){
              if (res.data[0].userid == -1){
                Toast(res.data[0].errormsg)
              } else{
                localStorage.setItem('xuYaoMiMa', 0)
                localStorage.setItem('userid', res.data[0].userid)
                localStorage.setItem('userInfo', JSON.stringify(res.data[0]))
                getWaterMarkTxt({
                  username:res.data[0].username
                }).then(res=>{
                  const ressy = res.data;
                  if(ressy && ressy.length > 0){
                    const syobj = ressy[0];
                    if(syobj.show_watermark == 1){
                      localStorage.setItem("sysShuiYin",JSON.stringify(syobj))
                      setWaterMark(
                        syobj.watermark_line1,
                        syobj.watermark_line2,
                        syobj.watermark_line3
                      )
                    }else{
                      localStorage.removeItem("sysShuiYin")
                    }
                  }
                })
                sessionStorage.setItem('out', '2')
                Toast(this.$t('login.loginTxt9'))
                if (window.DingDing){
                  // 启用钉钉，开始鉴权
                  this.$refs.ddBind.checkDdUserid()
                } else{
                  this.goto()
                }
              }
            } else {
              Notify({
                type: 'danger',
                message: res.errormsg
              })
            }
          })
        }else{
          Notify({
            type: 'danger',
            message: "验证码不正确！"
          })
        }
      }
      
    },
    // 获取语言列表
    getLangue () {
      this.langid = '0'
      getLangList().then(res => {
        if (res.iserror === '0')
        {
          const data = res.data
          const langs = []
          const lang = []
          data.forEach(item => {
            langs.push({ text: item.langname, value: item.langid }) // ['简体中文', '繁体中文', 'English', 'Japan', 'french']
            lang.push(item.shortname) // ['zh', 'zh-TW', 'en', 'ja', 'fr']
          })
          this.langs = langs
          this.lang = lang
          if (localStorage.getItem('langid'))
          {
            this.language = this.langs[localStorage.getItem('langid')].value
            this.langid = localStorage.getItem('langid')
          }
        }
        // 获取密码策略信息
        getPwdPolicy().then(res => {
          const data = res.data[0]
          this.need_slider_code = data.need_slider_code
          this.vertify_mode = data.vertify_mode
          this.use_double_factor_login_auth = data.use_double_factor_login_auth
          this.use_phone_login = data.use_phone_login
        })
      })
    },
    handleCommand (command) {
      console.log(command, 'handleCommand()')
      // const langs = ['简体中文', '繁体中文', 'English', 'Japan', 'french']
      // const lang2 = ['zh', 'zh-TW', 'en', 'ja', 'fr']
      this.langid = command
      this.$i18n.locale = 'lang' + command
      localStorage.setItem('langid', this.langid)
      localStorage.setItem('language', this.langid)
      document.title = `${this.$t('module.title')}`
      // localStorage.setItem('langid', this.langid)
      // Cookies.set('language', this.lang[command])
    },
    // 跳转
    goto () {
      // 设置水印
      let fullpath = localStorage.getItem('V8_REDIRECT_PATH')
      if (fullpath)
      {
        localStorage.setItem('V8_REDIRECT_PATH', '')
        this.$router.push(fullpath)
      } else
      {
        this.$router.push('/home?v=' + new Date().getTime())
      }
    },
    //忘记密码界面
    handleForget () {
      this.$router.push('/ForgetPwd')
    }
  }
}
</script>
<style lang="less" scoped>
.selectActive {
  color: #ff7600 !important;
  border-bottom: 1px solid #ff7600 !important;
  padding-bottom: 10px !important;
}

.SMSverification_pop {
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }

    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }

  .content {
    position: relative;
    height: calc(100% - 96px);
    margin-top: 96px;

    .van-form {
      padding: 80px 40px;

      .van-cell {
        margin-bottom: 40px;
      }

      .textsss {
        color: red;
        font-size: 28px;
      }
    }

    .van-password-input {
      padding-top: 40px;

      .van-password-input__security {
        background: #f7f8fa;
      }

      .van-password-input__info {
        color: red;
        font-size: 28px;
      }
    }

    .footerBox {
      width: 100%;
      height: 80px;
      position: absolute;
      bottom: 40px;
      background: #fff;
      display: flex;
      align-items: center;

      .flexBox {
        width: 100vw;
        flex-wrap: nowrap;
        overflow: hidden;
        height: 80%;
        display: flex;
        justify-content: space-around;
      }
    }
  }
}

.login-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #fff;

  * {
    margin: 0;
    padding: 0;
  }
}

.login-bg {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 0 30px;

  .login-header {
    padding-top: 55px;

    h2 {
      font-size: 40px;
      color: #000;
      font-weight: bold;
      font-family: PingFang SC;
    }
  }

  .login-logo {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .form-group {
    width: 100%;

    .form-item {
      border-bottom: 1px #ccc solid;
      margin-top: 30px;
      height: 1.2rem;
      display: flex;
      align-items: center;

      .send-btn {
        width: 320px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-left: 1px solid #ccc;

        .send-btn-link {
          color: #666;
          font-size: 0.48rem;
        }
      }

      label img {
        width: 25px;
        position: absolute;
        margin-top: 2%;
      }

      input {
        outline: none;
        border: 0;
        background-color: transparent;
        color: #333;
        font-size: 36px;
        width: 100%;
      }
    }
  }

  .text_verification {
    margin-top: 30px;
    margin-bottom: 15px;
    display: flex;
    height: 1.2rem;
    border-bottom: 1px #ccc solid;

    .up1 {
      outline: none;
      border: 0;
      background-color: transparent;
      color: #333;
      font-size: 0.48rem;
      width: 40%;
      padding: 0;
    }

    .show_identify {}

    .changeCode {
      height: 100%;
      line-height: 1.2rem;
      cursor: pointer;
      background: rgba(255, 255, 255, 0.7);
      color: #000;
      font-size: 24px;
      padding: 0 6px;
    }
  }

  .dragVerify {
    height: 1.2rem;
    margin-top: 30px;
    display: flex;
    justify-content: center;

    .drag {
      --width: 134px !important;
      --pwidth: -134px !important;

      // background: red!important;
      /deep/.dv_text {
        width: 100%;
        color: red;
      }
    }

    .drag_verify {
      /deep/.dv_handler {
        background: #0b82f8 !important;
        display: flex;
        justify-content: center;
        align-items: center;

        .fa {
          margin-top: unset;
          color: #ffffff;
        }
      }
    }
  }

  .button-group {
    width: 90%;
    height: 86px;
    margin: 0 auto;
    margin-top: 116px;
  }

  .nav-footer {
    font-size: 14px;
    color: #666666;
    text-align: center;
    position: absolute;
    bottom: 17px;
    left: 50%;
    transform: translateX(-50%);
  }

  .login-btn {
    width: 100%;
    height: 86px;
    background: #0b82f8;
    opacity: 0.8;
    border-radius: 44px;
    display: block;
    line-height: 86px;
    color: #fff;
    text-align: center;
    font-weight: bold;
    font-size: 32px;
  }

  .form-password {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    margin-top: 15px;

    .rem-password {
      height: 60px;
      display: flex;
      align-items: center;
      line-height: 60px;

      input {
        width: 30px;
        height: 30px;
        display: block;
      }

      label {
        font-size: 30px;
        margin-left: 0.15rem;
        line-height: 60px;
        height: 60px;
        display: block;
        color: #999999;
      }
    }

    .forget-password {
      font-size: 30px;
      line-height: 60px;
      height: 60px;
      display: block;
      color: #999999;
    }
  }
}
.jinZhiYongHuDianJi{
  touch-action: none;
  background: #b4c6e1 !important;
}
</style>
